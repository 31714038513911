@import url(https://fonts.googleapis.com/css?family=Roboto);
html {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background: #2d2d37;

}

h1, h2, h3, h4, h5, h6 {
    color: white;
    font-size: 3rem;
}

p {
    color: white;
    font-size: 2rem;
}

form {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

button {
    color: white;
    background: #2d2d37;
    border: 1px solid white;
    margin: 8px;
    padding: 8px 20px;
    font-size: 1.2rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
button:hover{
    color: #2d2d37;
    background: white;
}

.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12%;
}

.input {
background-color: #2d2d37;
  border: 0;
  padding: 6px 0;
  font-size: 1rem;
  width: 80%;
  outline: 0;
  color: white;
  border-bottom: 1px solid white;
  margin-right: 8px;
  
}

.add-btn {
    width: 100px;
    
}

.todo-list-container {
    min-height: 300px;
}

.item {
    background-color: rgb(221,160,221, 0.5);
    padding: 1% 5%;
    width: 250px;
    text-align: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
.item.purchased {
    background: darkseagreen;
    text-decoration: line-through;
}

.footer-container {
    display: flex;
    justify-content: center;
    margin-top: 6%;   
}
.footer-container p, a {
    font-size: 1rem;
    text-decoration: none;
    color: white;
}

  /* media queries */

  @media screen and (max-width: 500px) {
     form {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         margin: 0;
         width: 90%;
     }
     button, .add-btn {
         width: 270px;
     }
     .item {
         padding: 0;
     }
     .footer-container {
         margin-top: 20%;
     }
  }
